.effect {
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    /* background-color: - add your own background-color */
    background-color: green;
    font-family: "Roboto", sans-serif;
    /* put your font-family */
    font-size: 18px;
    padding: 4px 0px;
    width: 150px;
    border-radius: 6px;
    overflow: hidden;
}

/* effect-1 styles */
.effect.effect-1 {
    transition: all 0.2s linear 0s;
}
.effect.effect-1:before {
    font-size: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
}
.effect.effect-1:hover {
    text-indent: -20px;
    cursor: pointer;
}
.effect.effect-1:hover:before {
    opacity: 1;
    text-indent: 0px;
}
