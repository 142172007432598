main {
    background-color: #f0f4f7;
}

button[type="auth"]:disabled {
    background-color: lightyellow;
    color: black;
}

button[type="auth"]:disabled:hover {
    cursor: not-allowed;
}

button[type="upload"]:disabled:hover {
    cursor: not-allowed;
}
button[type="edit"]:disabled {
    background-color: #add8e6;
    color: #ffff;
}

button[type="edit"]:disabled:hover {
    cursor: not-allowed;
}

button[type="save"]:disabled {
    background-color: #808080;
    color: #ffff;
}

button[type="save"]:disabled:hover {
    cursor: not-allowed;
}

button[type="cancel"]:disabled {
    background-color: #ff8080;
    color: #ffff;
}

button[type="cancel"]:disabled:hover {
    cursor: not-allowed;
}

button[type="smalldelete"]:disabled {
    background-color: #ff8080;
    color: #ffff;
}

button[type="smalldelete"]:disabled:hover {
    cursor: not-allowed;
}

button[type="smalldelete"]:disabled:hover {
    cursor: not-allowed;
}

button[type="addbtn"]:disabled {
    background-color: #90ee90;
    color: #ffff;
}

button[type="addbtn"]:disabled:hover {
    cursor: not-allowed;
}

/* .has-search .admin-search {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
} */

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}

.fixed-left,
.wrapper,
.tab-pane,
.content,
.page-content-wrapper {
    background-color: #d5dae6 !important;
}

/* #root {
  background-color: #d5dae6 !important;
} */

#root {
    background-color: #ffffff;
}
.sub-banner {
    background-color: #eef1f7;
    padding-bottom: 1rem;
    width: 1080px;
}

.tab-content {
    padding-left: 1rem;
    padding-right: 1rem;
}

.sponsor {
    width: 237px;

    float: left;
    margin: 3px;
    padding: 3px;
    background-color: #ff8080;
}

.sponsor-img {
    width: 230px;
    height: 200px;
}

.modals {
    font-size: 12px;
}
.modals > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.modals > .content {
    width: 100%;
    padding: 10px 5px;
}
.modals > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}
.modals > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

option[value=""] {
    color: #999;
    display: none;
}

option:first {
    color: #999;
}
/* 
.disabled-nav-link:hover {
  color: red !important;
  pointer-events: stroke;
} */

.lidisabled {
    pointer-events: none !important;
    opacity: 0.6 !important;
}

/* .input-radio[disabled] {

}

.input-radio label span[disabled] {
  cursor: not-allowed;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  text-transform: capitalize;
} */

input[type="radio"][disabled] + .radio-value-profile {
    background-color: #ffffff;
    cursor: not-allowed;
}

.menu-flags {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.menu-flags-disabled {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.flag-select img {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: 0em !important;
}
/* ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
ul.pagination li.page-item.active a.page-link {
  color: #445565 !important;
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}
ul.pagination a.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 1rem;
}
ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
} */
/* 
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
  font-size: 1.6em;
}

.fa-twitter:before {
  content: "\f099";
  font-size: 1.6em;
}

.fa-youtube-play:before {
  content: "\f16a";
  font-size: 1.6em;
  color: #ff0000;
}

.fa-instagram:before {
  content: "\f16d";
  font-size: 1.6em;
  color: #d560c2;
}
.fa-linkedin:before {
  content: "\f0e1";
  font-size: 1.6em;
}
.fa-phone:before {
  content: "\f095";
  font-size: 1.1em;
}

.fa-envelope-o:before {
  content: "\f003";
  font-size: 3em;
  color: black;
} */

.error-container {
    margin-top: 100px;
    background-color: #f0f4f7;
    padding-top: 30px;
    padding-bottom: 30px;

    text-align: center;

    border: 2px solid black;
}

.error-title {
    letter-spacing: 3px;
    padding-bottom: 10px;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.react-tagsinput-input {
    width: 100% !important;
}

.confirm-modal-styling {
    position: absolute;
    top: 114px;
    left: 136px;
    right: 134px;
    bottom: 120px;

    /* bring your own prefixes */

    background-color: white;
}

.react-confirm-popup {
    width: 600px;
    background-color: white;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.react-modal-confirm {
    z-index: 9999;
}

.react-modal-confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6) !important; /*dim the background*/
}
.react-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6) !important; /*dim the background*/
}

/* About Planner/Vendor Modal */
/* .modal {
  position: absolute !important;
  top: 75px !important;
} */

.invalid-feedback {
    font-size: 100% !important;
}

.modal-image-style {
    padding-left: 20px;
}

.up {
    color: #bf00ff;
    font-weight: bold;
    border-color: #bf00ff;
    border-width: 0 0 1px;
    border-style: solid;
}

.mix {
    color: #32cd32;
    font-weight: bold;
    border-color: #32cd32;
    border-width: 0 0 1px;
    border-style: solid;
}

.stream {
    color: #ff3503;
    font-weight: bold;
    border-color: #ff3503;
    border-width: 0 0 1px;
    border-style: solid;
}

.react-img-gallery {
    width: 1000px;
    height: 600px;
    cursor: auto;
}

.input-check {
    top: 4px;
    left: 0;
    z-index: 12;
    width: 16px;
    height: 16px;
}
.m-h-23 {
    min-height: 23px;
}
.main-confirm-popup {
}
.main-confirm-popup .title {
}
.main-confirm-popup button {
}
.ReactModal__Overlay.ReactModal__Overlay--after-open.react-modal-overlay {
    z-index: 9999;
}
.image-show {
    height: 38px;
    width: 70px;
    margin-right: 9px;
    background-position: center;
    background-size: cover;
}
.min-h {
    min-height: 487px;
}
.gray-color {
    color: #687189;
}

@media only screen and (min-width: 1020px) {
    .react-modal-styling {
        position: absolute;
        top: 114px;
        left: 136px;
        right: 134px;
        bottom: 120px;

        /* bring your own prefixes */
        background-color: white;
    }
    .about-vendor-modal-styling {
        position: absolute;
        top: 114px;
        left: 136px;
        right: 134px;
        bottom: 50px;

        /* bring your own prefixes */
    }
}

.editor-about-us-wrapper {
    height: 58vh !important;
}

@media only screen and (max-width: 720px) {
    .react-modal-styling {
        height: 100vh;
        /* bring your own prefixes */
        background-color: white;
    }
    .editor-about-us-content {
        position: relative;
        top: 10px;
    }
    .editor-about-us-wrapper {
        width: 99% !important;
        max-height: none;
        display: grid;
    }

    .modal-image-style {
        padding: 5px;
        width: 50px;
    }

    .about-us-wrap figure {
        min-width: fit-content !important;
        padding-right: 20px !important;
    }
    .about-caption {
        flex-wrap: wrap;
    }
    .rdw-editor-main {
        height: auto !important;
    }
}

@media only screen and (max-width: 926px) {
    @media only screen and (orientation: landscape) {
        .react-modal-styling {
            height: 100vh;
            /* bring your own prefixes */
            background-color: white;
        }
        .editor-about-us-content {
            position: relative;
            top: 10px;
        }
        .editor-about-us-wrapper {
            width: 99% !important;
            max-height: none;
            display: grid;
        }

        .modal-image-style {
            padding: 5px;
            width: 50px;
        }

        .about-us-wrap figure {
            min-width: fit-content !important;
            padding-right: 20px !important;
        }
        .about-caption {
            flex-wrap: wrap;
        }
        .rdw-editor-main {
            height: auto !important;
        }
    }
}

@media only screen and (max-width: 334px) {
    .editor-about-us-wrapper {
        height: 44vh !important;
        max-height: none !important;
    }
}

@media screen and (max-width: 414px) {
    .page-content-wrapper {
        margin-top: 171px;
        overflow-x: auto;
    }
}

@media screen and (max-width: 1020px) {
    .p-b-2 {
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 474px) and (min-width: 414px) {
    .page-content-wrapper {
        margin-top: 100px;
        overflow-x: auto;
    }
}

.consent-wrapper_container {
    padding: 0 15%;
}
.consent-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 5%;
}
