/* review screen */
.reviews {
    padding: 2.5%;
}
.uleft {
    float: left;
}
.uright {
    float: right;
}
.aggregate-rating {
    background-color: #fff;
    display: inline-block;
    margin: 0 5%;
    padding: 0 2% 2%;
    width: 70%;
}
.display-inline {
    display: inline;
}
.comment {
    background-color: #fff;
    margin: 2%;
    padding: 2%;
}
.leave-review_button {
    padding: 10px;
    background-color: #fff;
}

/* Leave review */
.star-body {
    align-items: center;
    background-color: #fdfdfd;
    display: inline-flex;
    margin: 2%;
    padding: 0 20px;
}
.rating {
    display: block;
    direction: rtl;
    unicode-bidi: bidi-override;
    text-align: center;
}
.rating .star {
    display: none;
}
.rating label {
    color: lightgray;
    display: inline-block;
    font-size: 22pt;
    margin: 0 -2px;
    transition: transform 0.15s ease;
}
.rating label:hover {
    transform: scale(1.35, 1.35);
}
.rating label:hover,
.rating label:hover ~ label {
    color: orange;
}
.rating .star:checked ~ label {
    color: orange;
}
.l-half {
    width: 50%;
}

.verified {
    font-size: 0.9rem;
    color: rgb(27, 171, 238);
    margin: 0 10px;
    display: inline-block;
}
.verified .MuiSvgIcon-fontSizeSmall {
    font-size: 1rem;
}

.rating-form {
    align-items: center;
    display: inline-block;
    width: 80%;
    margin: 2%;
}
.rating-form .uleft.l-half {
    width: 45%;
}
.rating-form .uright.l-half {
    width: 45%;
}
.rating-form input,
.rating-form textarea,
.rating-form select,
.rating-form option {
    background: #fff !important;
    border: #000 1px solid !important;
    border-radius: 2px !important;
    margin: 15px 0px;
}
.rating-form textarea {
    width: 100%;
}

.display-block {
    display: block !important;
}
/* review rating */

.heading {
    font-size: 25px;
    margin-right: 25px;
}

.checked {
    color: orange;
}

/* Three column layout */
.side {
    float: left;
    width: 15%;
    margin-top: 10px;
}

.middle {
    margin-top: 10px;
    float: left;
    width: 70%;
}

/* Place text to the right */
.right {
    text-align: right;
}
.reviews .row {
    margin: 0;
}

/* Clear floats after the columns */
.review-section .row:after {
    content: "";
    display: table;
    clear: both;
}

/* The bar container */
.bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
}

/* Individual bars */
.bar-5 {
    width: 60%;
    height: 10px;
    margin-top: 4%;
    background-color: #ffa500;
}
.bar-4 {
    width: 30%;
    height: 10px;
    margin-top: 4%;
    background-color: #ffa500;
}
.bar-3 {
    width: 10%;
    height: 10px;
    margin-top: 4%;
    background-color: #ffa500;
}
.bar-2 {
    width: 4%;
    height: 10px;
    margin-top: 4%;
    background-color: #ffa500;
}
.bar-1 {
    width: 15%;
    height: 10px;
    margin-top: 4%;
    background-color: #ffa500;
}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 400px) {
    .side,
    .middle {
        width: 100%;
    }
    .right {
        display: none;
    }
}

@media (max-width: 767px) {
    .aggregate-rating {
        margin: 0;
        width: 100%;
    }
    .leave-review_button {
        margin: 5% 0;
    }
    .reviews .uright.l-half {
        width: 100%;
    }
    .rating-form {
        width: auto;
    }
}
