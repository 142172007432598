.copyright-footer {
    padding: 10px 0;
    background: #26303d;
}
.short-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}
.short-nav li {
    width: auto;
    font-size: 16px;
    margin: 0 0 0 20px;
    display: inline-block;
}
.short-nav li:first-child {
    margin-left: 0;
}
.short-nav li a {
    color: #fff;
    display: block;
    font-weight: 500;
    position: relative;
    text-decoration: underline;
    text-transform: capitalize;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
.short-nav li a::before {
    left: 0;
    bottom: 0;
    content: "";
    right: 100%;
    height: 1px;
    position: absolute;
    background-color: #fff;
    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
.short-nav li a:hover::before {
    right: 0;
}
.copyright-footer .social-list {
    width: 100%;
    padding: 0;
    margin: 0 0 16px;
    list-style: none;
}

.copyright-footer .social-list li a {
    width: auto;
    height: auto;
    display: block;
    font-size: 20px;
}
.copyright-footer .social-list li a,
.short-nav li a {
    color: #7d838a;
}

.contact-list {
}
.social-list li {
    width: auto;
    margin-left: 20px;
    font-size: 14px;
    display: inline-block;
    vertical-align: sub;
}
.social-list li:first-child {
    margin: 0 !important;
}

.ReactModal__Overlay {
    -webkit-perspective: 600;
    perspective: 600;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 150ms ease-out;
}

.ReactModal__Content {
    -webkit-transform: scale(0.5) rotateX(-30deg);
    transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
    -webkit-transform: scale(1) rotateX(0deg);
    transform: scale(1) rotateX(0deg);
    transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content--before-close {
    -webkit-transform: scale(0.5) rotateX(30deg);
    transform: scale(0.5) rotateX(30deg);
    transition: all 150ms ease-in;
}

.ReactModal__Content.modal-dialog {
    min-width: 960px;
    padding-top: 9rem;
    border: none;
    background-color: transparent;
}
